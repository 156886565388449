import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import Layout, { Content } from './components/Layout';
import ContentNavigation from './components/Navigation/Content';
import HeroHeader from './components/heroheader';
import formatSlug from './lib/slugFormatter';
import TeaserContainer, { TeaserItem } from './components/Teaser';
import Grid from '@material-ui/core/Grid';
import { MarkdownRender } from './components/Programme/MarkdownRender';

export const query = graphql`
  query DestinationQuery($id: Int!) {
    allStrapiProgrammes(sort: { fields: Order, order: ASC }) {
      edges {
        node {
          strapiId
          Title
          Intro
          Order
          Key_Visual {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 430
                height: 302
                placeholder: DOMINANT_COLOR
              )
            }
          }
          Country {
            id
            Title
          }
          Category {
            Title
          }
        }
      }
    }
    strapiContinents(strapiId: { eq: $id }) {
      strapiId
      Title
      Description
      Key_Visual {
        publicURL
      }
    }
  }
`;

interface Props {
  data: any;
  pageContext: any;
  location: any;
}

const Continent: FunctionComponent<Props> = ({
  data,
  pageContext,
  location,
}) => {
  const { navigation } = pageContext;
  const programmes = data.allStrapiProgrammes.edges.map(
    (elem: any) => elem.node
  );
  const continent = data.strapiContinents;
  const title = `BUNAC Programmes in ${continent.Title}`;
  const thumbnail = continent.Key_Visual.publicURL;
  const countries = navigation
    .find((nav: any) => nav.id === continent.strapiId)
    .subLinks.map((s: any) => s.id);

  const countriesProgrammes = programmes
    .filter(
      (programme: any) =>
        programme.Country && countries.indexOf(programme.Country.id) >= 0
    )
    .map((programme: any) => {
      return {
        id: programme.strapiId,
        title: programme.Title,
        to: formatSlug([
          programme.Category.Title,
          programme.Country ? programme.Country.Title : 'world',
          programme.Title,
        ]),
        description: programme.Intro,
        thumbnail: programme.Key_Visual ? programme.Key_Visual : null,
        subLinks: [],
      };
    });

  // Country Overview
  const TeaserList = () => (
    <TeaserContainer>
      <Grid item={true} xs={12}>
        <h1>{title}</h1>
      </Grid>
      {countriesProgrammes.map((link: any, i: number) => (
        <TeaserItem
          key={`${link.strapiId}-${i}`}
          link={link.to}
          title={link.title}
          thumbnail={link.thumbnail}
          description={link.description}
        />
      ))}
    </TeaserContainer>
  );

  return (
    <Layout title={pageContext.Title}>
      <HeroHeader title={pageContext.Title} backgroundUrl={thumbnail} />
      <ContentNavigation
        links={navigation}
        currentPathName={location.pathname}
        theme=""
      />
      <Content>
        {continent.Description && (
          <MarkdownRender content={continent.Description} />
        )}
        <TeaserList />
      </Content>
    </Layout>
  );
};

export default Continent;
